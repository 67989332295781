body {
  margin: 0;
  font-family: var(--font-light);
  background-color: #f8f8f8;
  font-size: var(--font-16);
}

code {
  font-family: var(--font-light);
}

:focus {
  outline: none;
  box-shadow: none;
  outline-offset: 0;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

a:hover {
  color: var(--color-primary) !important;
}

img {
  text-indent: -9999px;
}

.img-lab-icon {
  height: 75%;
}

.pointer {
  cursor: pointer;
}

a:hover,
a:active,
a:focus {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 0;
}

p {
  margin-bottom: unset;
}

.bg-light-ds {
  background-color: #F4F6FF !important;
}

.bg-white {
  background-color: white;
}

/* Header */

.app-logo {
  height: 7vmin;
  max-height: 53px;
  pointer-events: none;
  float: left;
  width: 100%;
}

.app-by-logo {
  height: 4vmin;
  pointer-events: none;
  float: right;
}

.header-topbar {
  height: 25px;
  background-color: var(--color-lightlilac);
  width: 100%;
}

.header-wrapper {
  min-height: 62px;
  background-color: var(--color-white);
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.header-wrapper-pro .header-content {
  padding: 10px 10px 10px 50px;
}

.header-content {
  min-height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}

.logo-center {
  justify-content: center !important;
  padding-left: 10px !important;
}

.app-logo-altern {
  pointer-events: none;
  max-height: 80px;
  max-width: 300px;
}

.logo-powerby {
  max-width: 94px;
  width: 100%;
}

.logo-left {
  justify-content: flex-start !important;
  padding-left: 10px !important;
}

/* footer */

.footer-wrapper {
  position: inherit;
  bottom: 0;
  background-color: #eef0ff;
  padding: 40px 0 48px;
  width: 100%;
  flex-shrink: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-rss {
  margin-top: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-rss i {
  color: var(--color-lightlilac-2);
  font-size: var(--font-16);
  margin: 0 15px;
}

.txt-footer {
  font-family: var(--font-regular);
  color: var(--color-secondary);
  font-size: var(--font-16);
  line-height: 24px;
  margin: 0;
  text-align: center;
}

.logo-footer {
  height: 45px;
  margin-bottom: 20px;
}

/* Content */

.img-not-found {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.img-error404 {
  max-width: 250px;
  width: 100%;
}

.pill-doctor {
  height: 149px;
  margin: 40px 0 30px;
}

.chanchito {
  height: 7.5rem;
}

.loading-yapp {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
}

.loading-yapp--normal {
  max-width: 100px;
  max-height: 100px;
}

.loading-yapp--pro {
  max-width: 60px;
  max-height: 60px;
}

.loading-yapp--alternative {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  margin: 100px 0;
}

.ic-tutorial {
  margin: 15px 0 24px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ic-tutorial--item {
  width: 100%;
  max-width: 584px;
  display: none;
}

.circle {
  width: 100px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: var(--color-green2);
}

.icons-files {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.icons-files i {
  padding: 10px;
  margin: 5px 10px;
  border-radius: 4px;
  border: 2px solid var(--color-green);
  color: var(--color-green);
}

.icon-up {
  transform: rotate(180deg);
}

.circle-small {
  width: 10px;
  height: 10px;
}

.wrapper-location {
  padding: 0 19px;
  width: 100%;
  padding-bottom: 100px;
  flex-grow: 1;
}

.content-app {
  background: var(--color-background-primary);
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
}

.product-shopping-bag {
  font-size: var(--font-16);
}

.product-shopping-bag .product-link {
  color: var(--color-primary);
  cursor: pointer;
}

.card-shoppping-bag {
  height: 7rem;
  width: 7rem;
  align-items: center;
  border-radius: 1rem;
}

.card-shoppping-bag img {
  object-fit: contain;
  height: 60%;
  width: 60%;
}

.more-detail button {
  width: 100%;
  margin: unset;
  padding: unset;
}

.beneficiaryList .container-beneficiaries label {
  font-size: var(--font-14);
}

.container-beneficiaries {
  min-height: 300px;
  padding-top: 5rem;
}

.bag-content {
  height: auto;
  overflow-y: auto;
  align-content: flex-start;

  border-radius: 8px;
  margin-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
}

.ul-check {
  padding-left: 2%;
}

.li-check {
  margin: 10px 0;
}

.li-check::marker {
  content: url('/src/assets/img/blue_check.svg');
}

.li-check span {
  margin-left: 5px;
}

/* Card quotation */

.content-quotetation {
  display: flex;
  flex-direction: column;
}

.quotation-card {
  border-radius: 16px;
  background-color: var(--color-white);
  border: 1px solid #e9e6fe;
  margin-bottom: 24px;
}

.quotation-card .accordion:nth-child(+ 2n) {
  margin-top: -2px;
}

.quotation-card .accordion:last-child .card {
  border-bottom: 0 !important;
}

.quotation-card .card:nth-child(+ 2n) {
  margin-top: -2px;
}

.quotation-card .accordion~.card {
  border-bottom: 0 !important;
}

.quotation-card--header {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
}

.quotation-card--header-img {
  width: 40px;
  height: 40px;
  border: 1px solid #c8c8c8;
  border-radius: 40px;
}

.quotation-card--header-title {
  font-size: var(--font-14);
  font-family: var(--font-bold);
  color: var(--color-secondary);
  margin: 0 7px;
}

.quotation-card--header-left {
  display: flex;
  align-items: center;
  width: 60%;
}

.quotation-card--header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto;
}

.quotation-card--header-right .txt-title {
  font-size: var(--font-12);
  list-style: var(--font-16);
  font-family: var(--font-regular);
  color: var(--color-secondary);
}

.quotation-card--header-right .txt-price {
  font-size: var(--font-14);
  list-style: var(--font-16);
  font-family: var(--font-bold);
  color: var(--color-secondary);
}

.quotation-card--center .card {
  border-top: 1px solid #c9cfff !important;
  border-bottom: 1px solid #c9cfff !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0;
}

.quotation-card--center .card-header {
  margin: 0 !important;
  margin-bottom: 1px;
  padding: 18px 24px !important;
  background-color: var(--color-white) !important;
  border-bottom: 1px solid #c9cfff !important;
}

.quotation-card--center .card-header .col {
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
}

.quotation-card--center .card-body {
  padding: 18px 24px !important;
}

.quotation-card--bottom {
  margin-top: -2px;
  min-height: 77px;
  display: flex;
  align-items: center;
}

.content-quotetation--item {
  width: 100%;
}

.content-quotetation--item:first-child hr {
  display: none;
}

/* Checkbox */

.custom-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: var(--font-22);
  user-select: none;
  height: 19px;
  margin: 0;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark i {
  font-size: var(--font-18);
  color: var(--color-white);
  display: none;
}

.custom-checkbox:hover input~.checkmark {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-checkbox input:checked~.checkmark {
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.custom-checkbox input:checked~.checkmark i {
  display: block;
}

.custom-checkbox::after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox input:checked~.checkmark::after {
  display: block;
}

.custom-checkbox-img {
  width: 60px;
  height: 60px;
  background-color: var(--color-white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* checkbox altern */

.custom-checkbox-altern {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: var(--font-22);
  user-select: none;
  height: 19px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.custom-checkbox-altern input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox-altern input:disabled+.checkmark-altern,
.custom-checkbox-altern input:disabled~.custom-checkbox-txt {
  opacity: 0.3;
}

.checkmark-altern {
  position: absolute;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-theme .checkmark-altern {
  border: 1px solid var(--theme-color);
}

.checkmark-altern i {
  font-size: var(--font-12);
  color: var(--color-white);
  display: none;
}

.custom-checkbox-altern:hover input~.checkmark-altern {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-checkbox-altern input:checked~.checkmark-altern {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.color-theme input:checked~.checkmark-altern {
  background-color: var(--theme-color) !important;
  border: 1px solid var(--theme-color) !important;
}

.custom-checkbox-altern input:checked~.checkmark-altern i {
  display: block;
}

.custom-checkbox-altern::after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox-altern input:checked~.checkmark-altern::after {
  display: block;
}

.custom-checkbox-altern-img {
  width: 60px;
  height: 60px;
  background-color: var(--color-white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox-txt {
  color: var(--color-secondary);
  margin-left: -10px;
}

/* switch */

.switch-default {
  z-index: 0;
}

.switch-default .custom-control-label::before {
  background-color: #bdbdbd;
  border: #bdbdbd solid 1px;
  outline: none;
  width: 32px;
  height: 17px;
  border-radius: 17px;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

.switch-default .custom-control-label::after {
  background-color: #e0e0e0;
  outline: none;
}

.switch-default .custom-control-label::after {
  top: 4px;
  left: -36px;
  width: 17px;
  height: 17px;
  border-radius: 14px;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(0.95rem);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border: 0;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.switch-defaul input:focus {
  outline: none !important;
  outline-offset: 0 !important;
}

/* stepper */

.input-stepper {
  width: 30px;
  height: 28px;
  background-color: var(--color-white);
  border: 0;
  text-align: center;
  font-family: var(--font-regular);
  font-size: var(--font-19);
  border: 1px solid var(--theme-color-secondary);
}

.btn-stepper--left {
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  color: var(--color-white);
}

.btn-stepper--right {
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  color: var(--color-white);
}

/* breadcrumbs */

.breadcrumbs {
  font-size: var(--font-16);
  font-family: var(--font-bold);
  line-height: var(--font-16);
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  min-height: 30px;
}

.breadcrumbs a {
  text-decoration: none;
  color: inherit;
}

.breadcrumbs i {
  color: var(--theme-color);
  margin-right: 11px;
  cursor: pointer;
}

/*  */

.content-card-info {
  margin-bottom: 0;
}

.card-info {
  background-color: var(--color-white);
  margin-left: -20px;
  margin-right: -20px;
  padding: 12px 20px;
  display: flex;
  border-top: 1px solid #e9e6fe;
  border-bottom: 1px solid #e9e6fe;
}

.border-top-gray {
  border-top: 1px solid var(--color-gray-5);
  word-break: break-all;
  display: flex;
  align-items: flex-start;
  padding: 10px 5px;
  flex-direction: column;
}

.card-info:nth-child(+ n) {
  margin-top: -1px;
}

.card-info-input {
  height: 19px;
  border: 0;
  background-color: transparent;
  font-size: var(--font-12);
  color: var(--color-secondary);
  padding: 5px 0;
  width: 100%;
  font-family: var(--font-regular);
}

.card-info-input::placeholder {
  font-size: var(--font-12);
  color: var(--theme-color);
  padding: 5px 0;
  width: 100%;
  font-family: var(--font-regular);
}

.card-info-input-phone::placeholder {
  font-size: var(--font-12);
  color: var(--color-gray-2);
  padding: 5px 0;
  width: 100%;
  font-family: var(--font-regular);
}

.card-messege {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: 1px solid #e9e6fe;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-messege .title-big {
  max-width: 220px;
  margin-bottom: 23px;
}

.img-message {
  height: 122px;
}

.toggle-default {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.toggle-default .accordion {
  margin-top: -2px;
  border-bottom: 0px solid #c9cfff !important;
}

.toggle-default .accordion:last-child .card {
  border-bottom: 1px solid #c9cfff !important;
}

.toggle-default .card {
  border-top: 1px solid #c9cfff !important;
  border-bottom: 1px solid #c9cfff !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0;
}

.toggle-default .card-header {
  margin: 0 !important;
  margin-bottom: 1px;
  padding: 12px 20px !important;
  background-color: var(--color-white) !important;
  cursor: pointer;
  border-bottom: 0px solid #c9cfff !important;
}

.toggle-default .card-header .col {
  margin: 0 !important;
  padding: 0 !important;
}

.toggle-default .card-body {
  padding: 8px 20px 18px !important;
}

.shoppingBagCheckout .datepicker input {
  border-radius: 0;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #ced4da;
  color: #495057;
  font-size: var(--font-20);
  line-height: 1.5;
  border-radius: 0.3rem;
}

.conten-default {
  padding: 0 19px;
  width: 100%;
}

.content-location {
  max-width: 466px;
  width: 100%;
}

.content-location-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  max-width: 77%;
}

.find-location {
  margin-top: 15px;
}

.product-detail {
  margin-bottom: 10px;
  margin-left: 6px;
  margin-right: 6px;
  display: grid;
  min-width: 99px;
}

.product-detail div {
  max-width: 99px;
}

.content-bioequivalent .product-detail {
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 11px;
  max-width: 167px;
  width: 46%;
  min-width: 167px;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid var(--color-gray-5);
}

.content-bioequivalent .product-detail div {
  max-width: 100%;
}

.product-detail-item {
  margin-left: auto;
  margin-right: auto;
  max-width: 167px;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid var(--color-gray-5);
}

.product-detail .product-btn {
  display: flex;
  align-items: flex-end;
}

.product-detail-img {
  width: 80%;
  height: auto;
}

.product-detail-content-img {
  background-color: var(--color-white);
  width: 60px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto 16px;
}

.product-detail-content-img img {
  width: auto;
  height: 48px;
  font-size: var(--font-8);
  text-indent: -9999px;
  white-space: pre-wrap;
}

.product-detail .content-txt {
  max-width: 99px;
  width: 100%;
}

/* new card items shopping card  */

.exam-detail {
  background: var(--color-white);
  border-radius: 8px;
  width: 100%;
  max-width: 280px;
  min-width: 280px;
  border: 1px solid var(--color-gray-5);
  padding: 16px 12px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
}

.product-detail-new {
  background: var(--color-white);
  border-radius: 8px;
  width: 100%;
  min-width: 140px;
  border: 1px solid var(--color-gray-5);
  padding: 16px 12px;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}

/* .product-detail-new:nth-child(2n) {
  margin-right: 0 !important;
} */

/* .new-patient .product-detail-new:nth-child(2n) {
  margin-right: 8px !important;
} */

.product-detail-new-img {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-detail-new-img img {
  max-width: 56px;
  text-indent: -9999px;
}

.content-bioequivalent .content-txt {
  max-width: 100%;
}

.alert-price {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 47px;
  background-color: #00a19b;
  font-size: var(--font-14);
  font-family: var(--font-bold);
  color: #e0e0e0;
  border-radius: 16px 16px 0 0;
}

.input-custom {
  font-size: var(--font-12);
  color: var(--color-secondary);
  padding: 11px 18px;
  height: 38px;
  background: var(--color-white);
  border: 1px solid #e9e6fe;
  box-shadow: -2.08333px -2.08333px 10.4167px rgba(255, 255, 255, 0.6),
    2.08333px 2.08333px 10.4167px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 70px;
}

.input-custom::placeholder {
  font-size: var(--font-12);
  color: var(--color-gray-1);
}

.img-corner {
  padding: 10px;
  position: absolute;
  right: -20px;
  top: -20px;
}

.input-custom-secondary {
  font-size: var(--font-12);
  color: var(--color-secondary);
  padding: 11px 18px;
  height: 48px;
  background: transparent;
  border: 1px solid var(--color-gray-5);
  border-radius: var(--border-radius-input);
  min-width: 70px;
}

.input-custom-secondary::placeholder {
  font-size: var(--font-12);
  font-family: var(--font-bold);
  color: var(--color-gray-4);
}

.input-custom-textarea {
  font-size: var(--font-12);
  color: var(--color-secondary);
  padding: 11px 18px;

  background: transparent;
  border: 1px solid var(--color-gray-5);
  border-radius: var(--border-radius-input);
  min-width: 70px;
}

.content-edit-products {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.content-product {
  max-width: 70px;
}

.product-img {
  width: 60px;
  height: 60px;
  background-color: var(--color-white);
  border-radius: 8px;
}

.content-search--right {
  display: none;
}

.text-search--number {
  top: 11px;
  position: relative;
}

.content-search--right .title-primary {
  font-size: var(--font-18);
  margin: 9px 27px;
}

.separate-product-shopping {
  border-top: 1px solid #d9d9d9;
  margin: 0;
}

.content-shopping-product {
  padding: 9px 27px;
}

.content-webview {
  border: none;
  padding: 0;
  margin: 0 -19px;
  background-color: var(--color-gray-2);
  height: calc(100vh);
  width: calc(100% + 38px);
  overflow-x: hidden;
  overflow-x: auto;
}

.content-minsal {
  width: 100%;
  max-width: 300px;
}

/* PRO */

.content-home-pro {
  padding-top: 15px;
  background-repeat: repeat-x;
  background-size: auto;
  background-position-y: -60px;
}

.title-menu-left {
  width: 100%;
  text-align: left;
  max-width: 340px;
  margin: 0 auto;
  padding-left: 8px;
}

.content-img {
  width: 57px;
  height: 57px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
  background-color: var(--color-white);
  padding: 5px;
  display: flex;
  align-items: center;
}

.content-img img {
  width: 100%;
}

.img-doctors {
  max-width: 315px;
  width: 100%;
}

.link-shared {
  color: var(--theme-color);
  font-size: var(--font-30);
}

/* Modal */

.modal-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0px;
  background-color: #f4f6ff;
  padding: 0 19px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-page header {
  margin-left: -19px;
  margin-right: -19px;
}

.modal-page-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1050;
}

.modal-custom {
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  height: auto;
  max-width: 288px;
  background-color: white;
  border-radius: 8px;
  padding: 35px 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-custom-login {
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  height: auto;
  max-width: 305px;
  background-color: white;
  border-radius: 8px;
  /* padding: 35px 20px; */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-custom-register {
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  height: auto;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 35px 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.modal-pro-register {
  max-width: 600px;
  padding: 2em;
  margin: auto;
}

.modal-custom-teldoc {
  max-width: 288px;
  padding-top: 16px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 70vh;
  flex-direction: row;
}

.content-modal-vh {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}

.content-middle-modal {
  overflow-y: auto;
}

.footer-modal-scroll {
  margin-top: auto;
}

.modal-custom .btn-default--small {
  width: 100% !important;
  max-width: 212px;
}

.modal-custom-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.68);
  z-index: 1100;
}

.modal-content-preview {
  display: flex;
  justify-content: center;
}

.modal-from-bottom {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.payment-method-active {
  background-color: rgba(77, 186, 182, 0.2);
}

.payment-logo {
  max-width: 47px;
  max-height: 26px;
}

.modalt-img {
  width: 100px;
  height: 100px;
  border-radius: 13px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto 12px;
}

.modal-text-product {
  font-size: var(--font-16);
  font-family: var(--font-regular);
  color: var(--color-secondary);
  max-width: 130px;
  margin: 0 auto;
}

.modal-legal {
  max-width: 70vw;
  max-height: 90vh;
}

.modal-legal .iframe-legal {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-color: transparent;
  max-height: 70vh;
  height: 70vh;
  width: 100%;
}

.content-order {
  max-width: 220px;
  width: 100%;
  margin: 0 auto 12px;
  display: flex;
}

.content-order-icon i {
  font-size: var(--font-25);
  width: 25px;
  height: 25px;
}

.modal-type-forms {
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  height: 85vh;
  max-width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 35px 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-type-form {
  height: 70vh;
  border: 0;
}

/* New Drugs */

.content-new-drugs {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 0;
}

.content-new-drugs::-webkit-scrollbar {
  display: none;
}

.content-new-drugs .product-detail {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 8px;
  width: 140px;
  min-width: 140px;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 15px 14px;
  border: 1px solid var(--color-gray-5);
  display: flex;
  flex-direction: column;
}

.content-new-drugs {
  max-width: 594px;
  margin: 0 auto;
}

.content-new-drugs-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin: 0 auto;
}

.content-new-drugs-filter .product-detail {
  margin-bottom: 8px;
}

.product-border {
  border: 0.4px solid var(--color-gray-5);
  border-radius: 0.25rem;
  width: 45px;
  height: 42px;
  max-width: 45px;
  max-height: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.modal-inside {
  max-height: 12rem;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.4rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: var(--color-gray-5);
}

/* Drugs interactions */

.content-drugs-interactions {
  max-width: 500px;
  margin: 0 auto;
}

.content-patient-info {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.content-patient-info .select-custom-altern {
  max-width: 100%;
  width: 100%;
  margin-right: 0;
  margin-bottom: 12px;
}

.content-patient-info .custom-checkbox-altern {
  min-width: 80px;
}

.tag-result {
  border: 1px solid var(--color-primary);
  min-height: 30px;
  display: inline-flex;
  align-items: center;
  font-size: var(--font-12);
  color: var(--color-gray-1);
  padding: 5px 5px 5px 15px;
  border-radius: 30px;
}

.tag-result i {
  font-size: var(--font-16);
}

.content-drug-interacion-search>div {
  padding: 12px 0 !important;
}

/* vademecum */

.content-vademecum {
  max-width: 584px;
  margin: 0 auto;
  background: transparent;
}

.card-vademecum {
  background-color: var(--color-white);
  padding: 12px 20px;
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
}

/* Product Detail Pro */

.product-tabs-details {
  padding: 1rem;
  border-bottom: 3px solid var(--color-primary);
}

.text-tabs-details {
  font-family: 'Nunito-Bold';
  font-size: var(--font-16);
  color: var(--color-primary);
  margin-bottom: 0;
}

.product-details-content {
  width: 100%;
  max-width: 584px;
  margin: 0 auto;
}

.product .product-details-left {
  max-width: 100%;
  width: 100%;
}

.product-details-left .content-vademecum {
  padding: 0 0 5px;
  margin-bottom: 15px;
}

.product-details-right {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.content-card-detail {
  width: 100%;
  margin: 0 auto;
  padding-top: 24px;
}

.content-form-send-email {
  position: relative;
}

.content-form-send-email .btn-modal-back {
  position: absolute;
  top: -25px;
  left: -8px;
}

.modal-icon-send-mail i {
  color: var(--color-secondary);
  font-size: var(--font-48);
}

/* Tracking */

.content-msg-tracking {
  max-width: 285px;
  width: 100%;
  background: var(--color-white);
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
  margin: 35px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-msg-tracking img {
  max-width: 60px;
  margin: 0 auto;
}

.content-order-disabled i,
.content-order-disabled h4,
.content-order-disabled p {
  color: var(--color-gray-4) !important;
}

.icon-remover {
  width: 32px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.ic_add_button {
  width: 0.6875rem;
  margin-right: 0.5rem;
}

.ic_photo_preview {
  width: 2.125rem;
  height: 3.0625rem;
}

.ic_remove_photo {
  position: absolute;
  left: 1.375rem;
  top: -0.375rem;
}

.minsal-img {
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 16px;
}

/*  */

.content-img-medication-sheet {
  max-width: 60px;
  min-width: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-img-new-agreement {
  height: 51px;
  width: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #828282;
  border-radius: 9999px;
}
.brand-img-new-details {
    height: 41px;
    width: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dde0ec;
    border-radius: 9999px;
    background-color: white;
}

.content-img-medication-sheet img {
  text-indent: -9999px;
}

.content-filter-new-drugs {
  margin-bottom: 24px;
  margin-top: 8px;
}

.content-responsive {
  /* width: 100%; */
  margin-left: 50px;
  margin-right: 50px;
  max-width: var(--max-screen-resposive);
}

.content-responsive-sm {
  /* width: 100%; */
  margin-left: 30px;
  margin-right: 30px;
  max-width: var(--max-screen-resposive);
}

.content-header-new {
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;

}


.content-responsive-btns {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {

  .content-responsive,
  .head-top {
    padding: 0px;
  }
}

.content-card-product {
  display: flex;
  flex-wrap: wrap;
}

.content-header-new {

  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 100%;

}

.content-card-product .btn-card-interaction {
  width: 100%;
  margin-bottom: 12px;
}

.content-info-home {
  margin: 36px auto 8px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.content-info-home-item {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-gray-5);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
}

.content-info-home-new {
  margin: 16px auto 8px;
  display: flex;
  flex-direction: column;
  max-width: 214px;
}

.content-info-home-new-item {
  margin-bottom: 8px;
  margin-right: 8px;
  border: 1px solid var(--color-gray-5);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ic-normalize-home {
  width: 35px;
  height: 35px;
}

.content-form-home {
  max-width: 290px;
  display: flex;
  flex-direction: column;
}

.bg-home-pro {
  background-image: url(../assets/img/bg_linear_white.png);
  background-repeat: repeat-x;
  background-position: top center;
  background-size: 1px 145px;
}

.content-img-programs {
  max-width: 48px;
  min-width: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

div[class$='-singleValue'] .laboratory-name {
  display: none;
}

.alert-icon {
  font-size: var(--font-52);
  color: var(--color-red);
  width: 52px;
  height: 52px;
  margin-bottom: 24px;
}

.content-banners-menu-pro {
  max-width: 584px;
  margin: 0 auto;
}

.alert-message {
  border-radius: 10px;
  border: 1px solid var(--color-red);
  padding: 16px;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  color: var(--color-red);
  background: var(--color-white);
}

.alert-message-without-color {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  padding: 0.4375rem 0.75rem;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  max-width: 288px;
}

.alert-message i,
.alert-message-without-color i {
  font-size: var(--font-24);
}

.size-content-responsive-btn {
  max-width: 214px;
}

.img-forgot-password {
  width: 167px;
  margin: 21px auto 16px;
}

.content-img-avatar {
  position: absolute;
  top: 15px;
  right: 16px;
  display: inline-flex;
}

.img-avatar-account {
  border-radius: 100%;
}

.img-header-avatar-acount {
  width: 32px;
  height: 32px;
}

.img-fail-validate {
  width: 70px;
  height: 63px;
  margin: 16px 0;
}

/* Checkout */
.content-checkout-delivery {
  border-top: 1px solid var(--color-gray2-ds);
  border-bottom: 1px solid var(--color-gray2-ds);
  background-color: var(--color-white);
  padding: 0 16px;
}

.content-checkout-discount {
  border-bottom: 1px solid var(--color-gray2-ds);
  background-color: var(--color-white);
  padding: 0 16px;
}

.content-checkout-shopping-detail {
  margin-top: 8px;
  margin-bottom: 32px;
  padding: 0;
  width: 100%;
}

.content-checkout-medication-detail {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--color-white);
  padding: 0 16px;
}

.content-checkout-total-price {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--color-white);
  padding: 16px 16px;
  margin-bottom: 24px;
}

.content-bg-white-resposive {
  padding: inherit 22px;
  border-radius: var(--border-radius);
  border: unset;
  margin-left: -19px;
  margin-right: -19px;
}

.content-add-prescription {
  width: 100%;
  height: 48px;
  border-radius: var(--border-radius-input);
  border: 1px solid var(--color-gray-5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  background-color: var(--color-white);
}

.content-add-prescription-modal {
  width: 15rem;
  height: 48px;
  margin: 0 auto;
  border-radius: var(--border-radius-input);
  border: 1px solid var(--color-gray-5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  background-color: var(--color-white);
}

.action-location {
  position: absolute;
  right: 8px;
  top: 18px;
}

.tel-codearea {
  position: absolute;
  left: 20px;
  top: 23px;
}

/* UC light class for codeArea on checkout */
.tel-codearea-light {
  position: absolute;
  left: 20px;
  top: 16px;
}

/*  */

.label-delivery {
  font-size: var(--font-10);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.24s ease-out;
  margin: 0;
  color: var(--color-gray-4);
}

/* Agreement content card medication sheet */
.content-yapp-agreement {
  background-color: var(--color-white);
  max-width: 584px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
}

.header-yapp-agreement {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-5);
}

.container-yapp-agreement {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-yapp-agreement-left,
.content-yapp-agreement-right {
  width: 100%;
}

.banner-discount {
  background: url(../assets/img/bg_banner_discount.svg) no-repeat bottom left;
  background-color: #d9deff;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 48px;
  cursor: pointer;
}

.yapp-agreement-copy-code {
  min-height: 48px;
  width: 100%;
  border: 1px solid var(--color-gray-5);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
}

.content-yapp-agreement-share {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}

/* Card Product Detail */
.card-product-detail {
  display: flex;
  background: var(--color-white);
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
  padding: 16px;
  position: relative;
}

.icon-agreement {
  position: absolute;
  right: 8px;
  top: 8px;
}

.img-refund-sura {
  width: 100%;
  height: 2rem;
}

.chip-tag-green {
  border: 1px solid #78C8C5;
  color: #78C8C5;
  outline: none;
  padding: 0px 6px;
  border-radius: 4px;
  display: inline-flex;
  font-size: 16px;
  line-height: 28px;
}

.chip-tag-blue {
  border: 1px solid #6578FF;
  color: #6578FF;
  outline: none;
  padding: 0px 6px;
  border-radius: 4px;
  display: inline-flex;
  font-size: 14px;
  line-height: 22px;
}

.apply-refund {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.dots-on-100 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 12rem;
}

.separator-dots {
  position: relative;
  bottom: 19px;
  left: 55px;
}

.modal-from-bottom {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.modal-selection-active {
  background-color: rgba(77, 186, 182, 0.2);
}

.vida-camara-loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #ff7415;
  z-index: 9;
}

.vida-camara-loader-img {
  position: absolute;
  margin: auto auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  animation-name: loader;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.input-btn-position {
  top: 8px;
  right: 18px;
}

.secondary-value {
  position: relative;
  bottom: 23px;
}

.modal-backdrop,
.modal {
  z-index: 1200 !important;
}

.content-flag-agreement {
  background-color: rgba(245, 192, 2, 0.21);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 8px;
  padding-right: 16px;
}

@keyframes loader {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.modal-from-bottom {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.modal-selection-active {
  background-color: rgba(77, 186, 182, 0.2);
}

/* Media queries */

@media (max-width: 400px) {
  .dots-on-100 {
    width: 6rem;
  }

  .app-logo-altern {
    max-width: 130px;
  }

  .powerby {
    max-width: 100px;
  }

  .content-pay-detail {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-gray-5);
    margin-bottom: 24px;
  }
}

@media (min-width: 425px) {
  .product-detail-new {
    max-width: calc(50% - 8px);
  }
}

@media (max-width: 767px) {
  .content-responsive {
    margin-left: 20px !important;
    margin-right: 20px !important;
    max-width: var(--max-screen-resposive) !important;
    width: auto !important;
  }
}

@media (max-width: 768px) {
  .btn-classic {
    width: 100%;
  }

  .content-app {
    height: 100vh;
  }

  .content-location {
    max-width: 100%;
  }

  .divider-sm {
    border-bottom: 3px solid #e9e6fe;
  }

  .minsal-img-container {
    flex-direction: column;
    align-items: center;
  }

  .just-padding {
    padding: 1.5rem 0rem;
  }

  .price-mobile-container {
    align-items: start !important;
  }

  .price-mobile {
    flex-direction: column;
    align-items: start !important;
  }

  .price-mobile div {
    margin-left: 0.5rem;
  }

  .modal-bottom-transition {
    opacity: 0;
    transform: translateY(300px);
    transition: all 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0px);
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(300px);
  }

  .btn-open-modal-bottom {
    border: 1px solid #dde0ec;
    border-radius: 8px;
    height: 2.5rem;
    max-width: 17.9375rem;
  }

  .btn-open-modal-bottom-full {
    border: 1px solid #dde0ec;
    border-radius: 8px;
    height: 2.5rem;
    background-color: white;
  }

  .tablet-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .ml-md-20 {
    margin-left: 20px;
  }

  .content-location {
    max-width: 100%;
    width: 100%;
  }

  .content-location-text {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    width: auto;
  }

  .content-location-text p {
    margin: 0 !important;
  }

  .find-location {
    margin-left: 20px;
    margin-top: 0 !important;
  }

  .content-quotetation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .content-quotetation .quotation-card {
    margin-right: 20px;
  }

  .separation-blue {
    margin: 10px 0;
    width: 100%;
  }

  .powerby {
    margin-left: 14px;
  }

  .content-home {
    max-width: 980px;
    margin: 10px auto;
    width: 100%;
    border-radius: 8px;
  }

  .content-new-home {
    margin: 10px auto;
    width: 100%;
    border-radius: 8px;
  }

  .content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .wrapper-location {
    max-width: 980px;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .content-search {
    display: flex;
    padding-top: 13px;
  }

  .content-search--left {
    width: 100%;
    margin-right: 15px;
  }

  .content-search--right {
    width: 100%;
    max-width: 358px;
    background-color: var(--color-white);
    border-radius: 8px;
    display: block;
  }

  .content-search--product {
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 23px 30px;
  }

  /* .content-search--product .search-product {
    max-width: 350px;
  } */
  .content-search--product .title-primary {
    margin-bottom: 20px;
    font-size: var(--font-18);
  }

  .content-search--product .title-search {
    font-size: var(--font-14);
    margin-bottom: 8px !important;
  }

  .product-detail {
    margin: 0 5px 15px 0;
  }

  .bag-content {
    height: auto;
    justify-content: flex-start;
    /*max-height: 240px;*/
  }

  .bag-content .lh-14 {
    max-height: 30px;
    overflow: hidden;
  }

  .content-bioequivalent .bag-content {
    max-height: 100%;
  }

  .bag-bioequivalent .product-detail div {
    max-width: 99px;
  }

  .bag-bioequivalent .product-detail {
    margin: 0 20px 15px 0;
  }

  .product-detail .content-txt,
  .product-detail div {
    max-width: 99px;
  }

  .content-bioequivalent .product-detail div {
    max-width: 100%;
  }

  .product-detail form {
    margin: 0 auto;
  }

  .bag-bioequivalent .product-detail .product-btn {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  .content-pharmacies {
    position: relative;
    display: flex;
    align-items: inherit;
    flex-wrap: wrap;
  }

  .scroll-active {
    position: relative;
  }

  .content-pharmacies .quotation-card {
    width: 308px;
    display: block;
    margin-right: 8px;
  }

  .cards-best-price .quotation-card {
    margin-top: 45px !important;
  }

  .cards-best-price .slick-slide:first-child .quotation-card {
    margin-top: 0 !important;
  }

  .content-product-detail {
    max-width: 358px;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 16px;
    margin: 0 auto 90px;
    border: 1px solid #e9e6fe;
  }

  .content-product-detail .card-info,
  .content-product-detail .toggle-default {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .content-product-detail .content-card-info>.title-primary {
    display: none;
  }

  .content-product-detail .content-card-info:nth-child(1) {
    margin-bottom: 0 !important;
  }

  .content-product-detail .content-card-info:nth-child(1) .card-info {
    border: none;
  }

  .content-product-detail .accordion:first-child .card {
    border-top: 0 !important;
  }

  .content-product-detail .accordion:last-child .card-header {
    border-bottom: 0 !important;
  }

  .content-checkout {
    display: flex;
    flex-wrap: wrap;
  }

  .content-checkout-left {
    width: 100%;
    margin-right: 0;
    padding: 0;
  }

  .content-checkout-left .card-info {
    margin: -1px 0 0;
    padding: 12px 19px;
  }

  .content-checkout-left .card-info:last-child,
  .content-checkout-right .card-info:last-child {
    border-bottom: none;
  }

  .content-checkout-right {
    max-width: auto;
    width: 100%;
    padding: 0 12px;
  }

  .content-checkout-right .card-info {
    margin: -1px 0 0;
    padding: 12px 19px;
  }

  .content-minsal {
    width: 100%;
    max-width: 90%;
    margin-top: 50px;
  }

  .content-minsal p {
    text-align: justify;
  }

  .toggle-default .accordion:last-child .card {
    border-bottom: 1px solid #c9cfff !important;
  }

  .toggle-default .accordion:last-child .card:last-child {
    border-bottom: 0px solid #c9cfff !important;
  }

  .toggle-default .accordion {
    border-bottom: 0px solid #c9cfff !important;
  }

  .cards-best-price .quotation-card {
    margin-top: 45px !important;
  }

  .cards-best-price .quotation-card:first-child {
    margin-top: 0 !important;
  }

  .cards-best-price .slick-slide:first-child .quotation-card {
    margin-top: 0 !important;
  }

  .cards-best-price .slick-slide .quotation-card {
    margin-top: 45px !important;
  }

  .content-home-pro {
    padding-top: 20px;
  }

  .content-new-drugs {
    flex-wrap: wrap;
    overflow-x: hidden;
  }

  .content-new-drugs .product-detail {
    margin-bottom: 10px;
  }

  .content-vademecum {
    margin-top: 0;
  }

  .content-vademecum .card-info {
    margin-left: 0;
    margin-right: 0;
    border-top: 0;
    border-bottom: 0;
    padding-top: 30px;
  }

  .content-vademecum .toggle-default,
  .content-card-info-pro .toggle-default {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .product-details-left {
    max-width: 320px;
    width: 100%;
  }

  .product-details-left .content-vademecum {
    padding: 0 0 5px;
    margin-bottom: 15px;
  }

  .product-details-right {
    max-width: 302px;
    width: 100%;
  }

  .content-patient-info .select-custom-altern {
    max-width: calc(50% - 6px);
    width: 100%;
    margin-right: 12px;
  }

  .content-patient-info .select-custom-altern:nth-child(even) {
    margin-right: 0;
  }

  .just-padding {
    padding: 0.75rem 1.25rem;
  }

  .content-new-drugs-filter {
    max-width: 100%;
    margin: inherit;
  }

  .content-card-product .btn-card-interaction {
    width: calc(50% - 8px);
  }

  .content-card-product .btn-card-interaction:nth-child(odd) {
    margin-right: 8px;
  }

  .content-responsive-btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .content-responsive-btns .btn-default--small,
  .content-responsive-btns .btn-default {
    margin-right: 12px;
    width: calc(50% - 12px);
  }

  .content-info-home {
    margin: 36px auto 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 584px;
    flex-wrap: wrap;
  }

  .content-info-home-item {
    margin-right: 8px;
    margin-bottom: 8px;
    display: flex;
    width: calc(50% - 8px);
  }

  .content-info-home-item:last-child {
    margin-right: 0;
  }

  .minsal-img {
    border-radius: 8px;
    width: 100%;
    max-width: 288px;
    margin-bottom: 3rem;
  }

  .size-content-responsive-btn {
    max-width: 287px;
  }

  .action-location {
    position: absolute;
    right: 16px;
    top: 18px;
  }

  .product-detail-new:nth-child(2n) {
    margin-right: 8px !important;
  }

  /* .product-detail-new:nth-child(8n) {
    margin-right: 8px !important;
  } */
  .product-detail-new {
    max-width: calc(20% - 8px);
  }

  .content-bg-white-resposive {
    margin-left: 0;
    margin-right: 0;
  }

  .ic-tutorial--item {
    display: block;
  }

  .ic-tutorial--small-mobile {
    display: none;
  }

  .content-checkout-total-price {
    margin-bottom: 0;
  }

  .content-pay-detail {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-gray-5);
    margin-bottom: 0.5rem;
    background-color: var(--color-white);
  }

  .content-yapp-agreement-left,
  .content-yapp-agreement-right {
    width: calc(50% - 8px);
  }

  .content-img-avatar {
    right: 0;
  }

  .modal-bottom-transition,
  .btn-open-modal-bottom,
  .btn-open-modal-bottom-full {
    display: none !important;
  }

  .modal-custom-teldoc {
    max-width: 433px;
  }

  .content-info-home-new {
    margin: 16px auto 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 435px;
  }

  .content-info-home-new-item {
    width: 100%;
    max-width: calc(50% - 8px);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .content-pharmacies {
    overflow-x: auto;
  }

  .content-pharmacies::-webkit-scrollbar {
    display: none;
  }

  .cards-best-price .quotation-card:first-child {
    margin-top: 0 !important;
  }

  .quotation-card {
    min-width: 300px;
  }
}

@media (max-width: 992px) {
  .tooltip-hover {
    display: none;
  }

  /* .content-responsive {
    width: 100%;
  } */
}

@media (min-width: 1024px) {
  .content-responsive {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .btn-classic {
    width: 20rem;
  }

  .separation-blue {
    margin: 10px 0;
    width: 100%;
  }

  .content-new-drugs {
    max-width: 888px;
  }

  .content-card-product .btn-card-interaction {
    width: calc(33.6% - 8px);
    margin-right: 8px;
  }

  .content-card-product .btn-card-interaction:nth-child(3n) {
    margin-right: 0px;
  }

  .product-details-content {
    width: 100%;
    max-width: 888px;
  }

  .content-vademecum {
    max-width: 888px;
  }

  .content-responsive-btns {
    display: flex;
    flex-direction: row;
  }

  .content-responsive-btns .btn-default--small,
  .content-responsive-btns .btn-default {
    margin-right: 12px;
    width: calc(33.3% - 12px);
  }

  .content-info-home {
    margin: 34px auto 34px;
    display: flex;
    flex-direction: row;
    max-width: 888px;
    flex-wrap: nowrap;
  }

  .content-info-home-item {
    margin-right: 12px;
    padding-right: 30px;
    margin-bottom: 0;
    max-width: 33.3%;
  }

  .content-info-home-item:last-child {
    padding-right: 0;
  }

  .minsal-img {
    border-radius: 8px;
    width: 100%;
    max-width: 430px;
    margin-bottom: 0;
  }

  .content-patient-info .select-custom-altern {
    max-width: calc(33.785% - 12px);
    width: 100%;
    margin-right: 12px !important;
  }

  .content-patient-info .select-custom-altern:nth-child(odd) {
    margin-right: 12px;
  }

  .content-patient-info .select-custom-altern:nth-child(3) {
    margin-right: 0 !important;
  }

  .content-checkout {
    flex-wrap: nowrap;
  }

  .content-checkout-right {
    max-width: 288px;
  }

  .content-checkout-shopping-detail {
    margin-top: 8px;
    padding: 0.5rem 1rem 1rem 1rem;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-5);
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .content-checkout-total-price {
    padding: 4px 0;
    margin-bottom: 0;
  }

  .content-checkout-delivery {
    padding: 0;
  }

  .content-checkout-discount {
    padding: 0;
  }

  .content-checkout-medication-detail {
    padding: 0;
  }

  .content-checkout-left {
    margin-right: 12px;
  }

  .product-detail-new {
    max-width: calc(20% - 8px);
  }

  .product-detail-new:nth-child(8n) {
    margin-right: 8px !important;
  }

  .product-detail-new:nth-child(6) {
    margin-left: 8px !important;
  }

  .content-pay-detail {
    border: 0;
    border-radius: 0;
  }

  .tooltip-click {
    display: none;
  }

  .content-info-home-new {
    margin: 16px auto 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 870px;
  }
}

.hr-vertical {
  width: 1px;
  height: 60px;
  color: #a9abbb;
  background-color: #a9abbb;
}

@media (min-width: 1200px) {
  .btn-classic {
    width: 25rem;
  }

  .separation-blue {
    margin: 10px 0 !important;
    width: 100%;
  }
}

.carousel-nav {
  display: none;
  justify-content: end;
  overflow-x: hidden;
}

.carousel-next,
.carousel-prev {

  color: var(--color-primary);
  background: transparent;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  padding-right: 0px;

}

@media (max-width: 768px) {
  .carousel-nav {
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 1200px) {
  .product-detail-new {
    max-width: 148px;
  }

  .product-detail-new:nth-child(8n) {
    margin-right: 0px !important;
  }

  .reverting-separated-card:nth-child(8n) {
    margin-right: 8px !important;
  }
}

@media (max-width: 1436px),
(min-width: 1256px) {
  .product-detail-new:nth-child(6n) {
    margin-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .content-pay-detail {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-gray-5);
    margin-bottom: 24px;
    background-color: var(--color-white);
  }
.content-bg-white-resposive {
  margin-left: 0px;
  margin-right: 0px;
  } 

  .content-bg-white-resposive {
    margin-left: 0px;
    margin-right: 0px;
  }
}
