.btn-classic {
  background: linear-gradient(0.4turn, var(--color-primary), #46cebe);
  text-align: center;
  border-radius: 2rem;
  border-color: unset;
  width: 30rem;
  height: 3rem;
}

.btn-default {
  background: var(--color-primary);
  text-align: center;
  border-radius: 2rem;
  border-color: transparent;
  width: 100%;
  height: 48px;
  font-size: var(--font-14);
  line-height: 19px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  border: 0px solid var(--color-primary) !important;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  background: var(--color-primary) !important;
  color: white;
  border: 0px solid var(--color-primary) !important;
}

.btn-default--small {
  background: var(--color-primary);
  text-align: center;
  border-radius: 2rem;
  border-color: transparent;
  width: 100%;
  height: 40px;
  font-size: var(--font-12);
  line-height: 19px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  border: 0px solid var(--color-primary) !important;
}

.btn-default--small:hover,
.btn-default--small:focus,
.btn-default--small:active {
  background: var(--color-primary) !important;
  color: white !important;
  border: 0 !important;
}

.btn-stroke {
  background: transparent;
  border: 1px solid var(--color-primary) !important;
  color: var(--color-primary);
  text-align: center;
  border-radius: var(--border-radius);
  width: 100%;
  font-size: var(--font-12);
  line-height: 19px;
}

.btn-stroke--altern {
  background: transparent;
  border: 1px solid var(--color-primary) !important;
  color: var(--color-primary);
  text-align: center;
  border-radius: 2rem;
  width: 100%;
  font-size: var(--font-14);
  line-height: 19px;
}

.btn-stroke:active,
.btn-stroke:focus,
.btn-stroke--altern:active,
.btn-stroke--altern:focus {
  background: transparent !important;
  color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}

.btn-stroke:hover,
.btn-stroke--altern:hover {
  background: rgba(0, 0, 0, 0.02) !important;
  color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-default:disabled {
  color: var(--color-gray-4);
  background-color: var(--color-gray-5) !important;
  border-color: var(--color-gray-5) !important;
  border-width: 0px;
  border: none;
}

.btn-count-cart {
  font-family: var(--font-light);
  /* Font-size 0.9rem */
  font-size: var(--font-14);
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border-radius: 0.75rem;
  display: inline-grid;
  align-items: center;
  left: 3rem;
  top: -0.5rem;
  position: absolute;
  background-color: var(--color-notification);
  border-color: transparent;
  border-width: 0;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  border-color: transparent;
}

.btn-link {
  background: none !important;
  border: none;
  color: var(--color-primary-ds);
  cursor: pointer;
  font-family: var(--font-light);
  font-size: var(--font-12);
  height: 24px;
  line-height: 1.5;
  padding: 0 !important;

}
.btn-link:hover {
  text-decoration: none;
  color:  var(--color-primary-ds);
}
.btn-link:focus {
  text-decoration: none;
}

.btn-add-cart {
  background-color: transparent;
  border: unset;
  float: right;
}

.btn-stepper-left {
  background-color: var(--color-primary);
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  border-color: var(--color-primary);
}

.btn-stepper-right {
  background-color: var(--color-primary);
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-color: var(--color-primary);
}

.btn-small {
  font-size: var(--font-10);
  padding: 7px 11px;
  border-radius: 28px;
  line-height: 12px;
}

.btn-lilac {
  background: rgba(101, 120, 255, 0.2);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.btn-lilac:hover {
  background: rgba(101, 120, 255, 0.1);
}

.btn-next {
  width: auto;
  padding: 10px 20px;
}

.btn-next-icon {
  padding: 10px 20px !important;
  width: 100%;
  font-size: var(--font-14);
}

.btn-next-icon i {
  font-size: var(--font-22);
  margin-right: 10px;
}

.btn-account,
.btn-account:hover,
.btn-account:active,
.btn-account:focus {
  background: var(--color-green-3) !important;
  color: var(--color-white);
}

.btn-delete {
  font-size: var(--font-20);
  color: var(--color-gray-1);
  cursor: pointer;
}

.btn-more-info {
  background: transparent;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
}

/* Btn circle */

.btn-circle {
  border-radius: 18px;
  width: 34px;
  height: 34px;
  border: 1px solid var(--color-gray-5);
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-circle i {
  font-size: var(--font-20);
  color: var(--color-primary);
}

.btn-circle-close {
  border-radius: 12px;
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-gray-5);
  background-color: var(--color-gray-5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
}

.btn-circle-close .icon-close {
  width: 13px;
  height: 13px;
  position: relative;
  transform: rotate(45deg);
  margin-left: 1px;
  margin-top: -1px;
}

.btn-circle-close .icon-close::after {
  content: '';
  height: 2px;
  width: 13px;
  border-radius: 2px;
  background-color: var(--color-primary);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.btn-circle-close .icon-close::before {
  content: '';
  height: 2px;
  width: 13px;
  border-radius: 2px;
  background-color: var(--color-primary);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}

.btn-circle-disabled {
  background-color: var(--color-gray-5) !important;
}

/* stepper */

.btn-stepper {
  width: 24px;
  height: 28px;
  background-color: var(--theme-color-secondary);
  border: 1px solid var(--theme-color-secondary);
  font-family: var(--font-bold);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-25);
  line-height: 35px;
}

/* Share */

.btn-circle-shared {
  background-color: var(--color-primary) !important;
  padding: 5px !important;
  border-radius: 25px;
}

.btn-circle-shared circle {
  fill: var(--color-primary);
}

.btn-ic-email-share path {
  transform: translate(-9px, -8px) scale(1.29) !important;
}

/* Btn Interaciones medicamentosa */

.btn-interaciones {
  background-color: var(--color-white);
  border-radius: 10px;
  border: 1px solid var(--color-gray-5);
  padding: 25px 20px;
}

.btn-interaciones .btn-interaciones-next i {
  color: var(--theme-color);
}

.btn-trasparent {
  background: transparent;
  border: 0;
  width: 30px;
  height: 30px;
}

/* Btn Actions */

.btn-card-interaction {
  background-color: var(--color-white);
  border-radius: 10px;
  border: 1px solid var(--color-gray-5);
  padding: 18px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  overflow-x: hidden;
  width: 100%;
}

.btn-card-interaction .btn-card-interaction-next {
  align-items: center;
}

.btn-card-interaction .btn-card-interaction-next i {
  color: var(--theme-color);
}

.btn-ca-vmore {
  max-height: 32px !important;
  min-width: 76px !important;
}

.btn-share-responsive {
  max-width: 213px;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 28px;
}
/* Btn Sidenav */

.btn-sidenav {
  width: auto;
  font-size: var(--font-14);
  padding: 5px 32px;
  background: transparent;
  border: 1px solid #6578ff !important;
}

.btn-sidenav:hover,
.btn-sidenav:active,
.btn-sidenav:focus {
  background: transparent !important;
  border: 1px solid #6578ff !important;
}

.btn-sidenav i {
  font-size: var(--font-20);
}

/* RRSS */
.btn-rrss {
  min-height: 40px;
  width: 100%;
  max-width: 176px;
  border-radius: 20px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-rrss img {
  width: 24px;
  height: 24px;
}

.btn-apple {
  background: var(--color-black);
  border: 1px solid var(--color-black);
}
.btn-facebook {
  background: var(--color-facebook);
  border: 1px solid var(--color-facebook);
}
.btn-google {
  background: var(--color-white);
  border: 1px solid var(--color-gray-5);
}

.btn-link-add-medication {
  display: flex;
  align-items: center;
  color: var(--theme-color-secondary);
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  font-size: var(--font-14);
}

.btn-link-add-medication i {
  font-size: var(--font-16);
  width: 16px;
  height: 16px;
}
/* btns square */
.content-btns-share {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.container-btn-square-input {
  padding: 16px 24px;
}

.btn-false-input {
  border-radius: 8px;
  width: 100%;
  height: 3rem;
  background-color: white;
  border: 1px solid var(--color-gray2-ds);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 1rem 1.1875rem;
}

.btn-square-share {
  border-radius: 8px;
  width: 43px;
  height: 43px;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-white);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-btn-square-share {
  font-size: var(--font-24);
  width: 24px;
  height: 24px;
  color: var(--color-white);
}

.text-btn-square-share {
  max-width: 60px;
}

.btn-square-share.ripple::before,
.btn-false-input.ripple::before {
  top: 0;
  left: 0;
}

.content-btn-square-share-lite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-square-share-lite {
  background-color: transparent;
  border: 0;
  width: 20px;
  height: 20px;
}

.btn-square-share-lite .icon-btn-square-share {
  color: var(--color-primary);
  font-size: var(--font-20);
  width: 20px;
  height: 20px;
}

.btn-share-send-email {
  width: 100% !important;
}

.content-share-send-email {
  width: 100% !important;
}

/* Media queries */

@media (min-width: 768px) {
  .btn-stepper {
    width: 28px;
    height: 28px;
    font-size: var(--font-24);
  }
  .btn-share-responsive {
    max-width: 284px;
  }
  .btn-card-interaction {
    max-width: calc(50% - 8px);
    margin-right: 8px;
  }
  .content-btns-share {
    width: calc(50% - 16px);
  }
  .content-share-send-email {
    width: auto !important;
  }
  .btn-share-send-email {
    width: 280px !important;
  }
}

@media (min-width: 992px) {
  .btn-card-interaction {
    max-width: calc(33.6% - 8px);
    margin-right: 8px;
  }
  .btn-card-interaction:nth-child(3n) {
    margin-right: 0px;
  }
}
