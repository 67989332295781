.quotation-card .alert-price,
.pharmacy-card .alert-price {
  background-color: var(--theme-color);
  color: var(--color-white);
}

.subscription-card .alert-price {
  background-color: var(--theme-color);
}

.quotation-card .pharmacy-card .dot,
.pharmacy-card .dot,
.subscription-card .dot {
  background: var(--theme-color) !important;
}

.quotation-card .icon-chevron-down,
.pharmacy-card .icon-chevron-down {
  color: var(--theme-color-secondary);
}

.subscription-card .icon-chevron-down {
  color: var(--color-highlight-ds);
}

.quotation-card .breadcrumbs i,
.pharmacy-card .breadcrumbs i,
.subscription-card .breadcrumbs i {
  color: var(--theme-color);
  margin-right: 11px;
  cursor: pointer;
}

.quotation-card .circle,
.pharmacy-card .circle,
.subscription-card .circle {
  background: var(--theme-color);
}
