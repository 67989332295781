.new-tracking {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  counter-reset: section;
}

.new-tracking-item {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  position: relative;
}

.new-tracking-item-dots::before {
  display: none;
  content: '';
  width: 6.25rem;
  position: absolute;
  top: 1rem;
  left: -3.125rem;
  height: 0.625rem;
  background: radial-gradient(
      farthest-side,
      var(--color-gray2-ds) 90%,
      transparent
    ),
    radial-gradient(farthest-side, var(--color-gray2-ds) 90%, transparent),
    radial-gradient(farthest-side, var(--color-gray2-ds) 90%, transparent),
    radial-gradient(farthest-side, var(--color-gray2-ds) 90%, transparent),
    radial-gradient(farthest-side, var(--color-gray2-ds) 90%, transparent),
    radial-gradient(farthest-side, var(--color-gray2-ds) 90%, transparent),
    radial-gradient(farthest-side, var(--color-gray2-ds) 90%, transparent),
    radial-gradient(farthest-side, var(--color-gray2-ds) 95%, transparent);
  background-size: 9px 9px, 7.5px 7.5px, 5.2px 5.2px, 3.5px 3.5px, 3.5px 3.5px,
    5.2px 5.2px, 7.5px 7.5px, 9px 9px;
  background-repeat: no-repeat;
  background-position: 0, 17%, 32%, 45%, 55%, 68%, 83%, 100%;
}

.active .new-tracking-item-dots::before {
  background: radial-gradient(
      farthest-side,
      var(--theme-color) 90%,
      transparent
    ),
    radial-gradient(farthest-side, var(--theme-color) 90%, transparent),
    radial-gradient(farthest-side, var(--theme-color) 90%, transparent),
    radial-gradient(farthest-side, var(--theme-color) 90%, transparent),
    radial-gradient(farthest-side, var(--theme-color) 90%, transparent),
    radial-gradient(farthest-side, var(--theme-color) 90%, transparent),
    radial-gradient(farthest-side, var(--theme-color) 90%, transparent),
    radial-gradient(farthest-side, var(--theme-color) 95%, transparent);
  background-size: 9px 9px, 7.5px 7.5px, 5.2px 5.2px, 3.5px 3.5px, 3.5px 3.5px,
    5.2px 5.2px, 7.5px 7.5px, 9px 9px;
  background-repeat: no-repeat;
  background-position: 0, 17%, 32%, 45%, 55%, 68%, 83%, 100%;
}

.new-tracking-item:last-child .new-tracking-item-dots {
  display: none;
}

.new-step-track--by-step {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--color-gray2-ds);
  border-radius: 2.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
}

.new-step-track--by-step::before {
  counter-increment: section;
  content: '' counter(section);
  font-family: var(--font-bold);
  color: var(--color-white);
  font-size: var(--font-16);
}

.new-step-track--description {
  font-size: var(--font-14);
  color: var(--color-gray1-ds);
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  margin-left: 0.5rem;
}

.new-step-track--description i {
  font-size: var(--font-25);
  margin-right: 0.9375rem;
}

.new-tracking .active .new-step-track--description {
  color: var(--theme-color);
}

.new-tracking .active .new-step-track--separation::after,
.new-tracking .active .new-step-track--separation::before,
.new-tracking .active .new-step-track--separation .middle::after,
.new-tracking .active .new-step-track--separation .middle::before,
.new-tracking .active .new-step-track--separation .center::after,
.new-tracking .active .new-step-track--separation .center::before {
  background-color: var(--theme-color);
}

.new-tracking .active .new-step-track--by-step {
  background-color: var(--theme-color);
}

.new-step-track--by-step-active {
  background-color: var(--theme-color);
  box-shadow: inset 0rem 0rem 0rem 0.3125rem var(--color-white);
}

.new-step-track--by-step-active::after {
  content: '';
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  position: absolute;
  border: 0.1875rem solid var(--theme-color);
  top: 0;
  left: 0;
}

.new-tracking .active .new-step-track--by-step::before {
  content: '\e910';
  font-family: 'icomoon';
  padding: 0rem 0.125rem;
}

@media (min-width: 48rem) {
  .new-tracking {
    flex-direction: row;
    justify-content: space-between;
  }
  .new-tracking-item {
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* max-width: 20%; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .new-step-track--description {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  .new-step-track--description i {
    font-size: var(--font-27);
    margin-right: 0;
  }
  .new-step-track--by-step {
    margin-bottom: 0.625rem;
  }
  .new-tracking-item-dots::before {
    display: block;
    width: 3.75rem;
    left: 100%;
    transform: translateX(-50%);
    background-size: 4px 4px;
  }
  .active .new-tracking-item-dots::before {
    background-size: 4px 4px;
  }
}

@media (min-width: 62rem) {
  .new-tracking-item-dots::before,
  .active .new-tracking-item-dots::before {
    width: 6.25rem;
    left: 100%;
    transform: translateX(-50%);
    background-size: 9px 9px, 7.5px 7.5px, 5.2px 5.2px, 3.5px 3.5px, 3.5px 3.5px,
      5.2px 5.2px, 7.5px 7.5px, 9px 9px;
  }
}
