.content-theme-color {
  position: fixed;
  right: 10px;
  bottom: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  z-index: 9999;
  border-radius: 50%;
}

.content-theme-color:hover::after {
  content: 'Change Color Theme';
  font-size: var(--font-8);
  color: var(--color-white);
  position: absolute;
  top: -20px;
  right: 0;
  white-space: nowrap;
  border-radius: 4px;
  background-color: var(--theme-color);
  padding: 2px 10px;
}

.content-theme-color input[type='color'],
.content-theme-color input[type='color']::-webkit-color-swatch,
.content-theme-color input[type='color']::-webkit-color-swatch-wrapper {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 15px;
}